<template>
	<div style="height: 100%">
		<el-form
			:model="userArray"
			:rules="rules"
			ref="userArray"
			label-width="160px"
			size="small"
			style="width: 600px"
		>
			<el-form-item label="账号" prop="account">
				<el-input v-model="userArray.account"></el-input>
			</el-form-item>
			<el-form-item label="密码" prop="password">
				<el-input v-model="userArray.password" show-password></el-input>
			</el-form-item>
			<el-form-item label="姓名" prop="userName">
				<el-input v-model="userArray.userName"></el-input>
			</el-form-item>
			<el-form-item label="联系电话" prop="phone">
				<el-input v-model="userArray.phone"></el-input>
			</el-form-item>
			<el-form-item label="身份证" prop="idCard">
				<el-input v-model="userArray.idCard" @input="idCardChange"></el-input>
			</el-form-item>
			<el-form-item label="年龄" prop="age">
				<el-input type="number" v-model="userArray.age"></el-input>
			</el-form-item>
			<el-form-item label="性别" prop="sex">
				<el-select v-model="userArray.sex" placeholder="请选择性别" style="width: 100%">
					<el-option label="男" value="男"></el-option>
					<el-option label="女" value="女"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="电工证" prop="electricianLicense">
				<el-input v-model="userArray.electricianLicense"></el-input>
			</el-form-item>
			<el-form-item label="电工证到期日" prop="electricianExpDate">
				<el-date-picker
					style="width: 100%"
					v-model="userArray.electricianExpDate"
					type="date"
					value-format="timestamp"
					placeholder="选择电工证到期日"
				></el-date-picker>
			</el-form-item>
			<el-form-item label="现居住地址" prop="address">
				<el-input v-model="userArray.address"></el-input>
			</el-form-item>
			<el-form-item label="在职状态" prop="quitStatus">
				<el-select v-model="userArray.quitStatus" placeholder="请选择在职状态" style="width: 100%">
					<el-option label="在职" :value="1"></el-option>
					<el-option label="离职" :value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="接单状态" prop="receivingOrder">
				<el-select
					v-model="userArray.receivingOrder"
					placeholder="请选择接单状态"
					style="width: 100%"
				>
					<el-option label="正常接单" :value="0"></el-option>
					<el-option label="暂不接单" :value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="入职日期">
				<el-date-picker
					style="width: 100%"
					v-model="userArray.inductionTime"
					type="date"
					value-format="timestamp"
					placeholder="选择日期"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="离职日期">
				<el-date-picker
					style="width: 100%"
					v-model="userArray.quitTime"
					type="date"
					value-format="timestamp"
					placeholder="选择日期"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="所属区域" prop="regionIdList">
				<el-select
					multiple
					v-model="userArray.regionIdList"
					placeholder="请选择区域"
					style="width: 100%"
					@change="regionChange"
				>
					<el-option
						v-for="item in regionList"
						:key="item.id"
						:label="item.regionName"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="所属角色" prop="roleIdList">
				<el-select
					multiple
					v-model="userArray.roleIdList"
					placeholder="请选择角色"
					style="width: 100%"
					@change="roleChange"
				>
					<el-option
						v-for="item in roleList"
						:key="item.id"
						:label="item.roleName"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="用户状态" prop="status">
				<el-select v-model="userArray.status" placeholder="请选择" style="width: 100%">
					<el-option :key="0" label="正常登录" :value="0"></el-option>
					<el-option :key="1" label="禁止登录" :value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="微信唯一标识" prop="openId">
				<el-input v-model="userArray.openId"></el-input>
			</el-form-item>
			<el-form-item label="身份证人像面照片" prop="idCardFrontImage">
				<el-upload
					action="#"
					accept="image/jpeg,image/png,image/tiff,image/vnd.svf"
					:file-list="userArray.idCardFrontImageList"
					list-type="picture-card"
					:auto-upload="false"
					:on-change="(file, fileList) => upload(file, fileList, 'idCardFrontImage')"
					:on-remove="(file, fileList) => commRemove(file, fileList, 'idCardFrontImage')"
					:on-preview="handlePictureCardPreview"
				>
					<i class="el-icon-plus"></i>
				</el-upload>
			</el-form-item>
			<el-form-item label="身份证国徽面照片" prop="idCardBackImage">
				<el-upload
					action="#"
					accept="image/jpeg,image/png,image/tiff,image/vnd.svf"
					:file-list="userArray.idCardBackImageList"
					list-type="picture-card"
					:auto-upload="false"
					:on-change="(file, fileList) => upload(file, fileList, 'idCardBackImage')"
					:on-remove="(file, fileList) => commRemove(file, fileList, 'idCardBackImage')"
					:on-preview="handlePictureCardPreview"
				>
					<i class="el-icon-plus"></i>
				</el-upload>
			</el-form-item>
			<el-form-item label="电工证照片" prop="electricianImage">
				<el-upload
					action="#"
					accept="image/jpeg,image/png,image/tiff,image/vnd.svf"
					:file-list="userArray.electricianImageList"
					list-type="picture-card"
					:auto-upload="false"
					:on-change="(file, fileList) => upload(file, fileList, 'electricianImage')"
					:on-remove="(file, fileList) => commRemove(file, fileList, 'electricianImage')"
					:on-preview="handlePictureCardPreview"
				>
					<i class="el-icon-plus"></i>
				</el-upload>
			</el-form-item>
			<el-form-item label="银行卡照片" prop="bankCardImage">
				<el-upload
					action="#"
					accept="image/jpeg,image/png,image/tiff,image/vnd.svf"
					:file-list="userArray.bankCardImageList"
					list-type="picture-card"
					:auto-upload="false"
					:on-change="(file, fileList) => upload(file, fileList, 'bankCardImage')"
					:on-remove="(file, fileList) => commRemove(file, fileList, 'bankCardImage')"
					:on-preview="handlePictureCardPreview"
				>
					<i class="el-icon-plus"></i>
				</el-upload>
			</el-form-item>
			<el-form-item label="自拍照" prop="selfImage">
				<el-upload
					action="#"
					accept="image/jpeg,image/png,image/tiff,image/vnd.svf"
					:file-list="userArray.selfImageList"
					list-type="picture-card"
					:auto-upload="false"
					:on-change="(file, fileList) => upload(file, fileList, 'selfImage')"
					:on-remove="(file, fileList) => commRemove(file, fileList, 'selfImage')"
					:on-preview="handlePictureCardPreview"
				>
					<i class="el-icon-plus"></i>
				</el-upload>
			</el-form-item>

			<el-form-item label="其他图片" prop="imageUrl">
				<el-upload
					action="#"
					accept="image/jpeg,image/png,image/tiff,image/vnd.svf"
					:file-list="userArray.commFileList"
					list-type="picture-card"
					:auto-upload="false"
					:on-change="(file, fileList) => upload(file, fileList, 'other')"
					:on-remove="(file, fileList) => commRemove(file, fileList, 'other')"
					:on-preview="handlePictureCardPreview"
				>
					<i class="el-icon-plus"></i>
				</el-upload>
			</el-form-item>
		</el-form>
		<div slot="footer" style="margin-top: 50px; margin-left: 80%">
			<el-button @click="cancleClick" size="mini">取 消</el-button>
			<el-button type="primary" @click="sureClick" size="mini">确 定</el-button>
		</div>
		<el-dialog :visible.sync="dialogImagePreview" append-to-body>
			<img width="100%" :src="imagePreviewUrl" alt="" />
		</el-dialog>
	</div>
</template>

<script>
	import { authorization } from '../../../authorization/authorization';
	import ImageCompressor from 'compressorjs';
	export default {
		name: 'UserAdd',
		props: { data: Object },
		data() {
			return {
				roleList: [],
				regionList: [],
				checkList: [],
				dialogFormAdd: false,
				dialogFormEdit: false,
				tableData: [],
				departmentList: [],
				choiceList: [],
				curriculumList: [],
				classList: [],
				imageUrl: '',
				imagePreviewUrl: '',
				dialogImagePreview: false,
				params: {
					account: '',
					userName: '',
					phone: '',
					page: 1,
					size: 10,
				},
				rules: {
					account: [
						{ required: true, message: '请输入账号', trigger: 'blur' },
						{ min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
					],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' },
						{ min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
					],
					userName: [
						{ required: true, message: '请输入姓名', trigger: 'blur' },
						{ min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
					],
					phone: [
						{ required: true, message: '请输入手机号', trigger: 'blur' },
						{ validator: this.validatePhone },
					],
					regionIdList: [
						{
							type: 'array',
							required: true,
							message: '请选择所属区域',
							trigger: ['blur', 'change'],
						},
					],
					roleIdList: [
						{ type: 'array', required: true, message: '请选择角色', trigger: ['blur', 'change'] },
					],
					status: [{ required: true, message: '请选择用户状态', trigger: 'blur' }],
					idCard: [
						{ required: true, message: '请输入身份证号', trigger: 'blur' },
						{ validator: this.validateIdCard },
					],
					sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
					age: [{ required: true, message: '请输入年龄', trigger: 'blur' }],
				},
				userArray: {
					id: '',
					account: '',
					password: '',
					userName: '',
					phone: '',
					imgUrl: '',
					roleIdList: [],
					status: '',
					openId: '',
					regionIdList: [],
					regionIds: '',
					address: '',
					inductionTime: null,
					quitTime: null,
					idCard: '',
					quitStatus: '',
					receivingOrder: '',
					commFileList: [],
					commFilePath: [],
					age: '',
					sex: '',
					electricianExpDate: '',
					electricianLicense: '',
					idCardFrontImageList: [],
					idCardFrontImageUrl: '',
					idCardBackImageList: [],
					idCardBackImageUrl: '',
					electricianImageList: [],
					electricianImageUrl: '',
					bankCardImageList: [],
					bankCardImageUrl: '',
					selfImageList: [],
					selfImageUrl: '',
				},
				total: 0,
				sizes: [10, 15],
			};
		},
		created() {
			this.formatData();
			this.querySysRoleList();
			this.queryRegionList();
		},
		watch: {
			data: function () {
				this.formatData();
			},
		},
		methods: {
			authorization,
			formatData() {
				this.userArray = { ...this.data };

				this.userArray.idCardFrontImageList = [];
				if (this.userArray.idCardFrontImageUrl !== null) {
					let obj = {};
					obj.name = 'idCardFrontImageUrl';
					obj.url = this.changeImageUrl(this.userArray.idCardFrontImageUrl);
					this.userArray.idCardFrontImageList.push(obj);
				}

				this.userArray.idCardBackImageList = [];
				if (this.userArray.idCardBackImageUrl !== null) {
					let obj = {};
					obj.name = 'idCardBackImageUrl';
					obj.url = this.changeImageUrl(this.userArray.idCardBackImageUrl);
					this.userArray.idCardBackImageList.push(obj);
				}

				this.userArray.electricianImageList = [];
				if (this.userArray.electricianImageUrl !== null) {
					let obj = {};
					obj.name = 'electricianImageUrl';
					obj.url = this.changeImageUrl(this.userArray.electricianImageUrl);
					this.userArray.electricianImageList.push(obj);
				}

				this.userArray.bankCardImageList = [];
				if (this.userArray.bankCardImageUrl !== null) {
					let obj = {};
					obj.name = 'bankCardImageUrl';
					obj.url = this.changeImageUrl(this.userArray.bankCardImageUrl);
					this.userArray.bankCardImageList.push(obj);
				}

				this.userArray.selfImageList = [];
				if (this.userArray.selfImageUrl !== null) {
					let obj = {};
					obj.name = 'selfImageUrl';
					obj.url = this.changeImageUrl(this.userArray.selfImageUrl);
					this.userArray.selfImageList.push(obj);
				}

				this.userArray.commFilePath = [];
				this.userArray.commFileList = [];
				if (this.userArray.imgUrl !== null && this.userArray.imgUrl !== undefined) {
					let imgUrlArray = this.userArray.imgUrl.split(',');
					for (let i = 0; i < imgUrlArray.length; i++) {
						if (imgUrlArray[i] !== '' && imgUrlArray[i] !== undefined) {
							let obj = {};
							obj.name = imgUrlArray[i];
							obj.url = this.changeImageUrl(imgUrlArray[i]);
							this.userArray.commFilePath.push(imgUrlArray[i]);
							this.userArray.commFileList.push(obj);
						}
					}
				}
			},
			validatePhone(rule, value, callback) {
				if (!value) {
					callback();
				} else {
					if (!new RegExp(/^1[0-9][0-9]\d{8}$/).test(value)) {
						callback('请输入正确格式的手机号码!');
					}
					callback();
				}
			},
			validateIdCard(rule, value, callback) {
				if (!value) {
					callback();
				} else {
					if (
						!new RegExp(
							/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
						).test(value)
					) {
						callback('请输入正确格式的身份证号码!');
					}
					callback();
				}
			},
			regionChange(e) {
				//编辑区域下拉框清空回旋
				this.$set(this.userArray, this.userArray.regionIdList, e);
			},
			roleChange(selectedRoles) {
				this.userArray.roleIdList = selectedRoles;
			},
			querySysRoleList() {
				//获取角色下拉数据
				this.axios({
					method: 'get',
					url: '/v1/web/sys-role/select-sys-role',
					params: this.params,
				}).then((res) => {
					this.roleList = res.data.data.list;
					console.log(this.roleList);
				});
			},
			queryRegionList() {
				//获取区域下拉数据
				this.axios({
					method: 'get',
					url: '/v1/web/region-manage/select-region-drop-down',
					params: this.params,
				}).then((res) => {
					this.regionList = res.data.data.list;
				});
			},
			idCardChange() {
				let year = this.userArray.idCard.substring(6, 10);
				let date = new Date();
				let nowYear = date.getFullYear();
				let age = nowYear - year;
				if (age > 0 && age <= 150) {
					this.userArray.age = age;
				}

				let sexNumber = this.userArray.idCard.substring(16, 17);
				if (sexNumber % 2 == 0) {
					this.userArray.sex = '女';
					this.$forceUpdate();
				} else if (sexNumber % 2 == 1) {
					this.userArray.sex = '男';
				}
			},
			handlePictureCardPreview(file) {
				this.imagePreviewUrl = file.url;
				this.dialogImagePreview = true;
			},
			getImageList(name) {
				let list;
				switch (name) {
					case 'idCardFrontImage':
						list = this.userArray.idCardFrontImageList;
						break;
					case 'idCardBackImage':
						list = this.userArray.idCardBackImageList;
						break;
					case 'electricianImage':
						list = this.userArray.electricianImageList;
						break;
					case 'bankCardImage':
						list = this.userArray.bankCardImageList;
						break;
					case 'selfImage':
						list = this.userArray.selfImageList;
						break;
					default:
						list = this.userArray.commFileList;
						break;
				}

				if (list == null || list == undefined) {
					list = [];
				}

				return list;
			},
			setImageList(name, list) {
				switch (name) {
					case 'idCardFrontImage':
						this.userArray.idCardFrontImageList = [...list];
						break;
					case 'idCardBackImage':
						this.userArray.idCardBackImageList = [...list];
						break;
					case 'electricianImage':
						this.userArray.electricianImageList = [...list];
						break;
					case 'bankCardImage':
						this.userArray.bankCardImageList = [...list];
						break;
					case 'selfImage':
						this.userArray.selfImageList = [...list];
						break;
					default:
						this.userArray.commFileList = [...list];
						break;
				}
			},
			setImageUrl(name, url) {
				switch (name) {
					case 'idCardFrontImage':
						this.userArray.idCardFrontImageUrl = url;
						break;
					case 'idCardBackImage':
						this.userArray.idCardBackImageUrl = url;
						break;
					case 'electricianImage':
						this.userArray.electricianImageUrl = url;
						break;
					case 'bankCardImage':
						this.userArray.bankCardImageUrl = url;
						break;
					case 'selfImage':
						this.userArray.selfImageUrl = url;
						break;
					default:
						this.userArray.commFilePath.push(url);
						break;
				}
			},
			upload(file, fileList, type) {
				if (type == 'other') {
					if (fileList.length >= 40) {
						this.$message.error('最多只能上传40张照片');
						return;
					}
				} else {
					if (fileList.length > 1) {
						fileList.splice(0, 1);
					}

					let imgList = this.getImageList(type);
					if (imgList != null && imgList.length > 0) {
						imgList.splice(0, 1);
						this.setImageList(type, imgList);
					}
				}

				this.clickUpload(type, file);
			},
			async clickUpload(type, file) {
				let fileRaw;
				let maxSize = 200 * 1024; //自己定义的文件大小，超过多少M就开始压缩(现在是200k)
				if (file.size > maxSize) {
					fileRaw = await this.compressFile(file.raw, 0.2);
					file.raw = fileRaw;
				}

				this.uploadImage(file, type);
			},
			uploadImage(file, type) {
				let obj = {};
				obj.name = file.name;
				let param = new FormData(); //创建form对象
				param.append('files', file.raw);
				this.axios({
					method: 'post',
					url: '/v1/h5/order-info/image-upload',
					data: param,
				})
					.then((res) => {
						let imgUrl = res.data.data.imageUrl;
						obj.url = this.changeImageUrl(res.data.data.imageUrl);
						obj.success = true;
						let imgList = this.getImageList(type);

						imgList.push(obj);
						this.setImageList(type, imgList);
						this.setImageUrl(type, imgUrl);
					})
					.catch((e) => {
						console.log(e);
						this.$message.error('上传失败');
					});
			},
			commRemove(file, fileList, type) {
				if (type == 'other') {
					let j = 0;
					for (let i = 0; i < this.userArray.commFileList.length; i++) {
						if (file.name === this.userArray.commFileList[i].name) {
							j = i;
						}
					}
					this.userArray.commFileList.splice(j, 1);
					this.userArray.commFilePath.splice(j, 1);
				} else {
					this.setImageList(type, []);
					this.setImageUrl(type, '');
				}
			},
			/**
			 * 压缩图片
			 * @param file
			 * @returns {Promise<unknown>}
			 */
			compressFile(file, quality) {
				return new Promise((resolve, reject) => {
					new ImageCompressor(file, {
						quality: quality, //压缩质量
						checkOrientation: false, //图片翻转，默认为false
						success(result) {
							resolve(result);
						},
						error(e) {
							reject(e);
						},
					});
				});
			},
			changeImageUrl(imageUrl) {
				//转换图片路径
				if (imageUrl === null || imageUrl === '') {
					return '#';
				}
				return '/api/v1/web/order-info/preview-image?path=' + imageUrl;
			},
			sureClick() {
				this.$refs['userArray'].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						if (this.userArray.commFilePath != null && this.userArray.commFilePath.length > 0) {
							this.userArray.imgUrl = this.userArray.commFilePath.join(',');
						}

						this.$emit('sureClick');
					}
				});
			},
			cancleClick() {
				this.$emit('cancleClick');
			},
		},
	};
</script>

<style scoped>
	.avatar-uploader-icon {
		border: 1px dashed #d9d9d9;
		font-size: 28px;
		color: #8c939d;
		width: 120px;
		height: 120px;
		line-height: 120px;
		text-align: center;
	}
	.avatar {
		width: 120px;
		height: 120px;
		display: block;
	}
	.btn-right {
		float: right;
		margin-top: 10px;
		margin-right: 20px;
		margin-bottom: 10px;
	}
</style>
